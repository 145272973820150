<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
// import receiptroComponent from "@/components/tabReceiptRoComponent";
import DatePicker from "vue2-datepicker";

// import { tableData } from "./dataAdvancedtable";
export default {
  page: {
    title: "เพิ่มข้อมูลออกใบกำกับภาษี",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // receiptroComponent,
    DatePicker,
  },
  data() {
    return {
      rowsRopart: [],
      optionTypeDc: [
        {
          name: "บาท",
          type: "bath",
        },
        {
          name: "เปอรเซ็น",
          type: "per",
        },
      ],
      optionsRoWhtPer: [
        {
          name: "ไม่ระบุ",
          id: 0,
        },
        {
          name: "3%",
          id: 3,
        },
      ],
      wages: {
        name: "ไม่ระบุ",
      },
      form: false,
      loading: false,
      wageOptions: [],
      wageOptionsRo: [],
      wageItem: {
        wageId: "",
        selectWage: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: {
          name: "บาท",
          type: "bath",
        },
      },
      part: [],
      partItem: {
        partId: "",
        selectPart: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: {
          name: "บาท",
          type: "bath",
        },
      },
      rowParts: [],
      roDcPer: 0,
      rowRoParts: [],
      rowRoWages: [],
      userBranchId: "",
      currentPage: 1,
      perPage: 10,
      totalpartNetPrices: "",
      totalpartPrices: "",
      totalpartDcPer: "",
      totalparttotalPrice: "",
      sumtotalDcPrice: "",
      saleType: "",
      totalwageNetPrices: "",
      totalwagePrices: "",
      totalwageDcPer: "",
      totalwagetotalPrice: "",
      sumtotalwageDcPrice: "",
      repairOrder: [],
      roNetPrice: "",
      roDcPrice: "",
      roVatPrice: "",
      filter: {
        nameTh: "",
        wpCode: "",
      },
      totalPage: "",
      roType: "",
      totalRows: 1,
      totalRecord: 0,
      pageOptions: [10, 25, 50, 100],
      selectMode: "single",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionTypeWp: [
        {
          id: 1,
          type: "W",
          nameTh: "ค่าแรง",
        },
        {
          id: 2,
          type: "P",
          nameTh: "ค่าอะไหล่",
        },
      ],
      fieldsWage: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "wageCode",
          sortable: true,
          label: "รหัสค่าแรง",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อค่าแรง(ไทย)",
        },
        {
          key: "nameEn",
          sortable: true,
          label: "ชื่อค่าแรง(อังกฤษ)",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsPart: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "partCode",
          sortable: true,
          label: "รหัสอะไหล่",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่ออะไหล่(ไทย)",
        },
        {
          key: "nameEn",
          sortable: true,
          label: "ชื่ออะไหล่(อังกฤษ)",
        },
        {
          key: "year",
          sortable: true,
          label: "ปี",
        },
        {
          key: "sellPrice",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      rowsWp: [],
      rowsP: [],
      rowsW: [],
      rowsWage: [],
      rowsPart: [],
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",
      wpSelect: {
        totalPrice: 0,
        vatPer: appConfig.vat,
        vatPrice: 0,
        netPrice: 0,
        dcPrice: 0,
        dcPer: 0,
        grandTotalPrice: 0,
      },
      title: "เพิ่มข้อมูลออกใบกำกับภาษี",
      items: [
        {
          text: "การเงิน",
          active: true,
        },
        {
          text: "ออกใบกำกับภาษี",
          active: false,
          href: "/tax-invoice",
        },
        {
          text: "เพิ่มข้อมูลออกใบกำกับภาษี (หรือแก้ไข)",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: "",
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      arrTest: [],
      branchMaster: [],
      rowsRo: [],
      totalRecords: 0,
      isHidden: false,
      branch: "",
      type: "",
      roId: "",
      serviceRo: [],
      selectRO: "",
      optionBranch: [],
      filterCreate: {
        branch: "",
        vin: "",
        licensePlate: "",
      },

      taxInvId: this.$route.params.taxInvId,
      paymentType: "",
      receipt: [
        {
          id: 1,
          receiptName: "ออกใบกำกับภาษีปกติ",
        },
        {
          id: 2,
          receiptName: "ออกใบกำกับภาษีเคลม Warranty",
        },
      ],
      payment: [
        {
          id: 1,
          payName: "ชำระเงินสด",
          paymentType: "CASH",
        },
        {
          id: 2,
          payName: "เครดิต",
          paymentType: "CREDIT",
        },
        // {
        //   id: 3,
        //   payName: "โอนจ่าย",
        //   paymentType: "CREDIT",
        // },
      ],
      fieldsCreate: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "roCode",
          sortable: true,
          label: "รหัสใบซ่อม",
        },
        {
          key: "customerFullNameTh",
          sortable: true,
          label: "ชื่อ-สกุล",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        {
          key: "vehicleBrandEn",
          sortable: true,
          label: "ยี่ห้อ",
        },
        {
          key: "vehicleModelEn",
          sortable: true,
          label: "รุ่น",
        },
        {
          key: "roDate",
          sortable: true,
          label: "วันที่สั่งซ่อม",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดรวม",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      userBranch: "",
      rows: [],
      overlayFlag: false,

      taxInvCode: "",
      licensePlate: "",
      customerNameTh: "",
      customerFamilyNameTh: "",
      branchName: "",
      cusName: "",
      payCash: "ชำระเงินสด",
      payCredit: "เครดิต",
      rowsCustomer: [],
      rowsVehicle: [],
      vehicle: "",
      taxInvDate: "",
      credit: "",
      dueDate: "",
      note: "",

      totalPrice: "",
      dcPer: "",
      dcPrice: "",
      netPrice: "",
      vatPer: "",
      vatPrice: "",
      grandTotalPrice: "",
      currentPageCreate: 1,
      perPageCreate: 10,

      rowsCreate: [],
    };
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    // this.taxInvId = this.$route.params.taxInvId;
    if (this.$route.params.taxInvId != null) {
      this.getData();
      this.getDataWagePart();
    }

    this.getDataCustomer();
    this.getDataVehicle();
    this.getDataCreate();
    //     const date = new Date();
    // date.setDate(date.getDate() + 7);

    // var d = new Date(),
    //   month = "" + (d.getMonth() + 1),
    //   day = "" + d.getDate(),
    //   year = d.getFullYear();
    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;
    // this.date = year + "-" + month + "-" + day;

    // const date = new Date();
    // d.setDate(d.getDate() + 7);
    // this.getDataWagePart();
  },
  created() {},
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    calDeleteWp(rowsWp) {
      this.wpSelect.totalPrice =
        parseFloat(this.totalPrice) - parseFloat(rowsWp.netPrice);
      this.wpSelect.dcPrice =
        (parseFloat(this.wpSelect.totalPrice) / 100) * this.dcPer;
      this.wpSelect.netPrice =
        parseFloat(this.wpSelect.totalPrice) -
        parseFloat(this.wpSelect.dcPrice);
      this.wpSelect.vatPrice =
        (parseFloat(this.wpSelect.netPrice) / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice =
        parseFloat(this.wpSelect.netPrice) + this.wpSelect.vatPrice;
    },
    calUpdateWp() {
      let pricePerOne = 0;
      let netPrices = 0;
      // let dcPer = 0;
      // let totalPrice = 0;
      this.rowsWp.forEach((item) => {
        pricePerOne += parseFloat(item.netPrice);
        netPrices += parseFloat(item.totalPrice);
        // dcPer += parseFloat(item.dcPer);
        // totalPrice += parseFloat(item.totalPrice) * item.amount;
      });
      this.wpSelect.netPrice = netPrices.toFixed(2);
      this.wpSelect.totalPrice = pricePerOne.toFixed(2);
      this.wpSelect.vatPrice = (this.wpSelect.netPrice / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice = netPrices + this.wpSelect.vatPrice;

      this.totalPrice = parseFloat(this.wpSelect.totalPrice);
      this.netPrice = parseFloat(this.totalPrice) - parseFloat(this.dcPrice);
      this.grandTotalPrice =
        parseFloat(this.netPrice) + parseFloat(this.vatPrice);
      // this.dcPer = (this.totalPrice / 100) * this.dcPer;
      this.dcPrice = (this.totalPrice / 100) * this.dcPer;
    },
    addWpInvoice: function(selected) {
      useNetw
        .post("api/tax-invoice/detail/store", {
          taxInvId: this.taxInvId,
          partId: selected.partId != null ? selected.partId : undefined,
          wageId: selected.wageId != null ? selected.wageId : undefined,
          type: this.roType.type,
          amount: 1,
          pricePerOne:
            selected.price != null ? selected.price : selected.sellPrice,
          totalPrice:
            selected.price != null ? selected.price : selected.sellPrice,
          dcPer: 0,
          dcPrice: 0,
          netPrice:
            selected.price != null ? selected.price : selected.sellPrice,
          vatPer: this.wpSelect.vatPer,
          vatPrice: this.wpSelect.vatPrice,
          grandTotalPrice:
            selected.price != null ? selected.price : selected.sellPrice,
          taxInvTotalPrice: this.wpSelect.totalPrice,
          taxInvDcPer: this.dcPer,
          taxInvDcPrice: this.wpSelect.dcPrice,
          taxInvNetPrice: this.wpSelect.netPrice,
          taxInvVatPer: this.vatPer,
          taxInvVatPrice: this.wpSelect.vatPrice,
          taxInvGrandTotalPrice: this.wpSelect.grandTotalPrice,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          this.getDataWagePart();
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    handleSearch() {
      // this.getRo(this.branchIdRo);
      if (this.roType.type === "W") {
        this.getDataWage();
      } else {
        this.getDataPart();
      }
    },
    handleChangePage(page) {
      this.currentPage = page;
      if (this.roType.type === "W") {
        this.getDataWage();
      } else {
        this.getDataPart();
      }
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      if (this.roType.type === "W") {
        this.getDataWage();
      } else {
        this.getDataPart();
      }
    },

    getDataWagePart: function(taxInvId) {
      this.overlayFlag = true;
      useNetw
        .get("api/tax-invoice/detail", {
          params: {
            taxInvId: this.taxInvId != null ? this.taxInvId : taxInvId,
          },
        })
        .then((response) => {
          this.rowsWp = response.data.data;
          this.rowsWp.forEach((element, index) => {
            if (element.type === "W") {
              this.rowsWp[index].selectWage = {
                wageId: element.wageId,
                nameTh: element.wageNameTh,
              };
            } else if (element.type === "P") {
              this.rowsWp[index].selectWage = {
                partId: element.partId,
                nameTh: element.partNameTh,
              };
            }
            //   else if (element.type === "L") {
            //   this.rowsWp[index].selectWage = {
            //     partId: element.partId,
            //     nameTh: element.partNameTh,
            //   };
            // } else if (element.type === "OS") {
            //   this.rowsWp[index].selectWage = {
            //     partId: element.partId,
            //     nameTh: element.partNameTh,
            //   };
            // } else if (element.type === "OTH") {
            //   this.rowsWp[index].selectWage = {
            //     partId: element.partId,
            //     nameTh: element.partNameTh,
            //   };
            // }
            // this.totalPricewage(this.rowsWp);
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataWp(type) {
      if (type.type === "W") {
        this.getDataWage();
      } else {
        this.getDataPart();
      }
    },
    getDataWage() {
      this.loading = true;
      useNetw
        .get("api/wage", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]": this.userBranchId,
            nameTh: this.filter.nameTh,
            wageCode: this.filter.wpCode,
          },
        })
        .then((response) => {
          this.rowsWage = response.data.data;
          this.totalPage = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataPart() {
      this.loading = true;
      useNetw
        .get("api/part", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]": this.userBranchId,
            nameTh: this.filter.nameTh,
            partCode: this.filter.wpCode,
          },
        })
        .then((response) => {
          this.rowsPart = response.data.data;
          this.totalPage = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    // calTotalTax(){

    // },

    totalPricewage(wageItem) {
      // if (this.wageItem.dcMethod.type === "per") {
      wageItem.totalPrice = parseFloat(wageItem.pricePerOne) * wageItem.amount;
      wageItem.totalPrice = wageItem.totalPrice.toFixed(2);
      wageItem.dcPrice =
        (parseFloat(wageItem.totalPrice).toFixed(2) / 100) * wageItem.dcPer;
      wageItem.netPrice =
        parseFloat(wageItem.totalPrice).toFixed(2) - wageItem.dcPrice;
      wageItem.grandTotalPrice =
        parseFloat(wageItem.netPrice).toFixed(2) - wageItem.vatPrice;
      // wageItem.vatPer =
      // parseFloat(wageItem.netPrice).toFixed(2) - wageItem.vatPrice;
      wageItem.vatPrice = parseFloat(wageItem.netPrice / 100) * this.vatPer;
      this.calUpdateWp();

      // this.calUpdateWp(wageItem);
      // this.wpSelect.vatPrice =
      // (parseFloat(this.wpSelect.netPrice) / 100) * this.vatPer;
      // let pricePerOne = 0;
      // let netPrices = 0;
      // let dcPer = 0;
      // let totalPrice = 0;
      // let sum = 0;
      // this.rowsWp.forEach((item) => {
      //   pricePerOne += parseFloat(item.pricePerOne) * item.amount;
      //   netPrices += parseFloat(item.netPrice);
      //   dcPer += parseFloat(item.dcPer);
      //   totalPrice += parseFloat(item.totalPrice) * item.amount;
      //   sum += (parseFloat(item.totalPrice) / 100) * item.dcPer;
      // });
      // this.totalwageNetPrices = netPrices.toFixed(2);
      // this.totalwagePrices = pricePerOne.toFixed(2);
      // this.totalwageDcPer = dcPer.toFixed(2);
      // this.totalwagetotalPrice = totalPrice.toFixed(2);
      // this.sumtotalwageDcPrice = sum.toFixed(2);
      // } else if (this.wageItem.dcMethod.type === "bath") {
      //   wageItem.totalPrice =
      //     parseFloat(wageItem.pricePerOne).toFixed(2) * wageItem.amount;
      //   wageItem.totalPrice = wageItem.totalPrice.toFixed(2);
      //   wageItem.dcPrice = wageItem.dcPer;
      //   wageItem.netPrice =
      //     parseFloat(wageItem.totalPrice).toFixed(2) - wageItem.dcPrice;
      //   let pricePerOne = 0;
      //   let netPrices = 0;
      //   let dcPer = 0;
      //   let totalPrice = 0;
      //   let sum = 0;
      //   this.rowRoWages.forEach(function(item) {
      //     pricePerOne += parseFloat(item.pricePerOne) * item.amount;
      //     netPrices += parseFloat(item.netPrice);
      //     dcPer += parseFloat(item.dcPer);
      //     totalPrice += parseFloat(item.totalPrice) * item.amount;
      //     sum += parseFloat(item.dcPer);
      //   });
      //   this.totalwageNetPrices = netPrices.toFixed(2);
      //   this.totalwagePrices = pricePerOne.toFixed(2);
      //   this.totalwageDcPer = sum.toFixed(2);
      //   this.totalwagetotalPrice = totalPrice.toFixed(2);
      //   this.sumtotalwageDcPrice = dcPer.toFixed(2);
      // }
    },
    calWpPrice(rowRo) {
      this.wpSelect.totalPrice =
        parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice) * 1;
      this.wpSelect.netPrice =
        parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice) * 1;
      // this.wpSelect.vatPrice =
      //   ((parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice) * 1) /
      //     100) *
      //   parseFloat(this.wpSelect.vatPer);

      this.wpSelect.totalPrice =
        parseFloat(this.totalPrice) +
        parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice);
      this.wpSelect.dcPrice =
        (parseFloat(this.wpSelect.totalPrice) / 100) * this.dcPer;
      this.wpSelect.netPrice =
        parseFloat(this.wpSelect.totalPrice) -
        parseFloat(this.wpSelect.dcPrice);
      this.wpSelect.vatPrice =
        (parseFloat(this.wpSelect.netPrice) / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice =
        parseFloat(this.wpSelect.netPrice) + this.wpSelect.vatPrice;
    },
    onRowSelected(rowRo) {
      this.selected = rowRo[0];
      this.calWpPrice(rowRo[0]);
      this.addWpInvoice(rowRo[0]);
      this.$refs["modalSearch"].hide();
    },
    onRowSelectedMulti(rowsMulti) {
      this.alertConfirm(rowsMulti.roId);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    getData: function(taxInvId) {
      this.overlayFlag = true;
      useNetw
        .get("api/tax-invoice/show", {
          params: {
            taxInvId: this.taxInvId != null ? this.taxInvId : taxInvId,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.taxInvCode = response.data.data.taxInvCode;
          this.roCode = response.data.data.roCode;
          this.taxInvDate = response.data.data.taxInvDate;
          this.dueDate = response.data.data.dueDate;
          this.totalPrice = response.data.data.totalPrice;
          this.dcPer = response.data.data.dcPer;
          this.dcPrice = response.data.data.dcPrice;
          this.netPrice = response.data.data.netPrice;
          this.vatPer = response.data.data.vatPer;
          this.vatPrice = response.data.data.vatPrice;
          this.grandTotalPrice = response.data.data.grandTotalPrice;
          this.credit = response.data.data.credit;
          this.selectRO = {
            roId: response.data.data.roId,
            roCode: response.data.data.roCode,
          };
          this.vehicle = {
            licensePlate: response.data.data.licensePlate,
            vehicleId: response.data.data.vehicleId,
          };
          this.cusName = {
            cusId: response.data.data.cusId,
            nameTh: response.data.data.customerNameTh,
            familyNameTh: response.data.data.customerFamilyNameTh,
          };
          this.branchName = {
            nameTh: response.data.data.branchName,
            branchId: response.data.data.branchId,
          };
          // this.grandTotalPrice = response.data.data.grandTotalPrice;
          if (response.data.data.paymentType != "CREDIT") {
            this.paymentType = {
              paymentType: response.data.data.paymentType,
              payName: this.payCash,
            };
          } else {
            this.paymentType = {
              paymentType: response.data.data.paymentType,
              payName: this.payCredit,
            };
          }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updateTaxInvoice: function() {
      this.overlayFlag = true;
      if (this.paymentType.paymentType === "CASH") {
        this.credit = null;
        this.dueDate = null;
      }
      useNetw
        .put("api/tax-invoice/update", {
          taxInvId: this.taxInvId,
          cusId: this.cusName.cusId,
          vehicleId: this.vehicle.vehicleId,
          taxInvCode: this.taxInvCode,
          taxInvDate: this.taxInvDate,
          credit: this.credit != null ? this.credit : undefined,
          dueDate: this.dueDate != null ? this.dueDate : undefined,
          note: this.note,
          totalPrice: this.totalPrice,
          dcPer: this.dcPer,
          dcPrice: this.dcPrice,
          netPrice: this.netPrice,
          vatPer: this.vatPer,
          vatPrice: this.vatPrice,
          grandTotalPrice: this.grandTotalPrice,
          paymentType:
            this.paymentType.paymentType != null
              ? this.paymentType.paymentType
              : this.paymentType.paymentType,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataCustomer: function() {
      this.loading = true;
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]": this.userBranchId,
            nameTh: this.cusName.nameTh,
          },
        })
        .then((response) => {
          this.rowsCustomer = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataCreate: function() {
      this.loading = true;
      useNetw
        .get("api/tax-invoice/repair-orders", {
          params: {
            page: this.currentPageCreate,
            perPage: this.perPageCreate,
            branchId:
              this.filterCreate.branch.branchId != null
                ? this.filterCreate.branch.branchId
                : this.userBranchId,
            roCode: this.filterCreate.roCode,
          },
        })
        .then((response) => {
          this.rowsCreate = response.data.data;
          // this.rowsCreate.total = response.data.total;
          this.rowsCreate.total = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          this.isHidden = true;
        });
    },
    onRowSelectedRo(rows) {
      // console.log(rows,'rows');
      this.alertConfirm(rows[0].roId);
    },
    alertConfirm(roId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการออกใบกำกับภาษีหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.confirmSubmitTaxInvoice(roId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    handleSearchCreate() {
      this.getDataCreate();
    },
    handleChangePageCreate(page) {
      this.currentPageCreate = page;
      this.getDataCreate();
    },
    handlePageChangeCreate(active) {
      this.perPageCreate = active;
      this.currentPageCreate = 1;
      this.getDataCreate();
    },
    confirmSubmitTaxInvoice: function(roId) {
      useNetw
        .post("api/tax-invoice/store", {
          roId: roId,
        })
        .then((response) => {
          this.taxInvId = response.data.taxInvId;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "detail-tax-invoiceId",
            params: { taxInvId: response.data.taxInvId },
          });
          this.getData(response.data.taxInvId);
          this.getDataCreate();
          this.getDataWagePart(response.data.taxInvId);
          this.$refs.modalCreateTaxInvoice.hide();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
    getDataVehicle: function() {
      this.isLoading = true;
      useNetw
        .get("api/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]": this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsVehicle = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    resetModal() {
      this.rowsRo.forEach((item, index) => {
        this.rowsRo[index].roId = item.roId;
        this.rowsRo[index].roId = null;
      });
    },
    alertDelete(rowsWp, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบรายการหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.calDeleteWp(rowsWp);
            this.deleteWpInvoice(rowsWp, index);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    updateWpInvoice: function(rowsWp) {
      this.calUpdateWp(rowsWp);
      this.overlayFlag = true;
      useNetw
        .put("api/tax-invoice/detail/update", {
          taxInvDetailId: rowsWp.taxInvDetailId,
          partId: rowsWp.partId != null ? rowsWp.partId : undefined,
          wageId: rowsWp.wageId != null ? rowsWp.wageId : undefined,
          type: rowsWp.type,
          amount: rowsWp.amount,
          pricePerOne: rowsWp.pricePerOne,
          totalPrice: rowsWp.totalPrice,
          dcPer: rowsWp.dcPer,
          dcPrice: rowsWp.dcPrice,
          netPrice: rowsWp.netPrice,
          vatPer: rowsWp.vatPer,
          vatPrice: rowsWp.vatPrice,
          grandTotalPrice: rowsWp.grandTotalPrice,
          taxInvTotalPrice: this.wpSelect.totalPrice,
          taxInvDcPer: this.dcPer,
          taxInvDcPrice: this.dcPrice,
          taxInvNetPrice: this.wpSelect.netPrice,
          taxInvVatPer: this.vatPer,
          taxInvVatPrice: this.wpSelect.vatPrice,
          taxInvGrandTotalPrice: this.wpSelect.grandTotalPrice,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          this.getData();
          // this.$refs["modalAmout"].hide();
          // this.getDataShowPart();
          //   this.getPoPart(this.poId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    deleteWpInvoice: function(rowsWp) {
      this.loading = true;
      useNetw
        .delete("api/tax-invoice/detail/delete", {
          data: {
            taxInvDetailId: rowsWp.taxInvDetailId,
            taxInvTotalPrice: this.wpSelect.totalPrice,
            taxInvDcPer: this.dcPer,
            taxInvDcPrice: this.wpSelect.dcPrice,
            taxInvNetPrice: this.wpSelect.netPrice,
            taxInvVatPer: this.vatPer,
            taxInvVatPrice: this.wpSelect.vatPrice,
            taxInvGrandTotalPrice: this.wpSelect.grandTotalPrice,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataWagePart();
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}

.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: auto;
}
.number {
  width: 140px;
  text-align: right;
}
.Dc {
  width: 100px;
}
.action {
  width: 40px;
}
.numberP {
  width: 140px;
  text-align: right;
}
.price {
  width: 170px;
  text-align: right;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
          :show="dismissCountDown"
          dismissible
          fade
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
          <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
        </b-alert>
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-7">
                  <div class="row">
                    <div class="col-5 col-sm-5 col-md-5">
                      <div class="mb-3">
                        <code> * </code>เลขใบแจ้งซ่อม:
                        <multiselect
                          v-model="selectRO"
                          label="roCode"
                          :options="rowsCreate"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          @input="onRowSelectedMulti"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2">
                      <div class="mt-3 position-relative">
                        <b-button
                          class="btn"
                          variant="info"
                          v-b-modal.modalCreateTaxInvoice
                        >
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-5">
                      <div class="mb-3">
                        <code> * </code>เลขที่ใบกำกับภาษี:
                        <b-form-input
                          v-model="taxInvCode"
                          disabled
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-7 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>ชื่อลูกค้า:
                        <multiselect
                          v-model="cusName"
                          label="nameTh"
                          :options="rowsCustomer"
                          :custom-label="customLabel"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>ป้ายทะเบียน:
                        <multiselect
                          v-model="vehicle"
                          label="licensePlate"
                          :options="rowsVehicle"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-5 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>ประเภทชำระ:
                        <multiselect
                          v-model="paymentType"
                          label="payName"
                          :options="payment"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>วันที่ออกใบกำกับ:
                        <date-picker
                          v-model="taxInvDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-3 col-sm-6 col-md-2">
                      <div class="mb-3">
                        เครดิต:
                        <b-form-input
                          v-model="credit"
                          class="form-control"
                          :disabled="paymentType.paymentType !== 'CREDIT'"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-9 col-sm-6 col-md-4">
                      <div class="mb-3">
                        วันครบกำหนดชำระ:
                        <date-picker
                          v-model="dueDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :disabled="paymentType.paymentType !== 'CREDIT'"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                      <div class="mb-3">
                        หมายเหตุ:
                        <b-form-textarea
                          v-model="note"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-5">
                  <div class="row mb-4">
                    <div class="col-12 d-flex justify-content-end">
                      <div
                        class="col-12 col-sm-12 col-md-12 bg-white m-0 text-end"
                        style="border-radius:10px;
                            margin:auto;
                            justify "
                      >
                        <div class="row align-items-center m-0">
                          <div class="col-12">
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ราคารวม :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="totalPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ส่วนลด (%):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="dcPer"
                                  class="form-control text-end"
                                  disabled
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                (%)
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ส่วนลด (บาท) :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="dcPrice"
                                  class="form-control text-end"
                                  disabled
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                สุทธิ :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="netPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ภาษี (%):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="vatPer"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                (%)
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ภาษี (บาท):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="vatPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2 mb-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                มูลค่ารวม :
                              </div>
                              <div class="col-6 font-size-24">
                                <b-form-input
                                  v-model="grandTotalPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button
                    class="btn float-end"
                    variant="info"
                    type="submit"
                    @click="updateTaxInvoice"
                  >
                    <!-- <i class="uil-search-alt"></i> -->
                    บันทึก
                  </b-button>
                </div>
              </div>

              <div class="row">
                <div class="card mt-4">
                  <div class="p-4 border-top">
                    <form class="needs-validation">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6">
                          <strong class="font-size-18"
                            >รายการค่าแรง/อะไหล่</strong
                          >
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 mb-2">
                          <button
                            v-b-modal.modalSearch
                            type="button"
                            class="btn btn-success float-end"
                            v-if="this.$route.params.taxInvId"
                          >
                            <i class="mdi mdi-plus"></i>
                            เพิ่มรายการค่าแรง/อะไหล่
                          </button>
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="col-12 col-sm-12 col-md-2 m-2">
                          <strong><code>* </code>ประเภทส่วนลด</strong>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 m-2">
                          <multiselect
                            class="wage"
                            :options="optionTypeDc"
                            v-model="wageItem.dcMethod"
                            @input="getDataWagePart()"
                            :close-on-select="true"
                            :internal-search="true"
                            open-direction="bottom"
                            :show-labels="false"
                            label="name"
                            placeholder=""
                          >
                            <span slot="noResult">ไม่พบข้อมูล</span> 
                          </multiselect>
                        </div>
                      </div> -->

                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>ชื่อรายการ</th>
                              <th>ราคา/หน่วย</th>
                              <th>ชั่วโมงงาน</th>
                              <th>มูลค่า</th>
                              <th>ส่วนลด</th>

                              <th>สุทธิ</th>
                              <!-- <th>ลบ</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(wageItem, index) in rowsWp"
                              :key="index"
                            >
                              <!-- <div v-if="wageItem.type === 'W'"></div> -->
                              <td>{{ index + 1 }}</td>
                              <!-- {{
                      wageItem
                    }} -->
                              <td scope="row">
                                <multiselect
                                  class="wage"
                                  :options="wageOptions"
                                  v-model="wageItem.selectWage"
                                  :loading="loading"
                                  :close-on-select="true"
                                  :internal-search="true"
                                  open-direction="bottom"
                                  @input="
                                    addNewWage(wageItem.selectWage, wageItem)
                                  "
                                  :show-labels="false"
                                  label="nameTh"
                                  placeholder=""
                                >
                                  <!-- <span slot="noResult">ไม่พบข้อมูล</span> -->
                                </multiselect>
                              </td>
                              <td class="number">
                                <input
                                  min="0"
                                  v-model="wageItem.pricePerOne"
                                  @input="totalPricewage(wageItem)"
                                  @keyup.enter="updateWpInvoice(wageItem)"
                                  class="form-control text-end"
                                  type="number"
                                />
                              </td>
                              <td class="Dc">
                                <input
                                  min="0"
                                  v-model="wageItem.amount"
                                  @input="totalPricewage(wageItem)"
                                  @keyup.enter="updateWpInvoice(wageItem)"
                                  class="form-control text-end"
                                  type="number"
                                />
                              </td>
                              <td class="number">
                                <input
                                  min="0"
                                  disabled
                                  v-model="wageItem.totalPrice"
                                  class="form-control text-end"
                                />
                              </td>
                              <td class="Dc">
                                <input
                                  min="0"
                                  v-model="wageItem.dcPer"
                                  @input="totalPricewage(wageItem)"
                                  @keyup.enter="updateWpInvoice(wageItem)"
                                  class="form-control text-end"
                                  append=".00"
                                  type="number"
                                />
                                <code
                                  >ได้รับส่วนลด :
                                  <strong>{{ wageItem.dcPrice }}</strong></code
                                >
                              </td>

                              <td class="number">
                                <input
                                  min="0"
                                  disabled
                                  v-model="wageItem.netPrice"
                                  class="form-control text-end"
                                />
                              </td>
                              <td>
                                <ul class="list-inline mb-0 action">
                                  <li class="list-inline-item">
                                    <a
                                      class="px-2 text-danger"
                                      v-b-tooltip.hover
                                      title="Delete"
                                      @click="alertDelete(wageItem, index)"
                                    >
                                      <i
                                        class="uil uil-trash-alt font-size-18"
                                      ></i>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <!-- <tr>
                              <th scope="row" colspan="6" class="text-end">
                                รวมค่าแรง :
                              </th>
                              <td class="text-end">
                                {{ this.totalwagetotalPrice }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดรวม (%) :
                              </th>
                              <td class="border-0 text-end">
                                {{ this.totalwageDcPer }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดรวม (บาท) :
                              </th>
                              <td class="border-0 text-end">
                                {{ this.sumtotalwageDcPrice }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                สุทธิรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{ this.totalwageNetPrices }}
                              </td>
                            </tr> -->
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
                <b-modal
                  ref="modalSearch"
                  id="modalSearch"
                  title="ค้นหาค่าแรง/ค่าอะไหล่"
                  hide-footer
                  centered
                  size="xl"
                >
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="col-12 col-sm-12 col-md-12 align-items-center"
                      >
                        <div class="row align-items-center">
                          <div class="col-12 col-sm-6 col-md-4 mb-4">
                            <code> * </code>ประเภท
                            <multiselect
                              :options="optionTypeWp"
                              v-model="roType"
                              @input="getDataWp(roType)"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              :show-labels="false"
                              label="nameTh"
                              placeholder="ค่าแรง/อะไหล่"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>

                          <div class="col-12 col-sm-8 col-md-4 mb-4">
                            รหัสค่าแรง/อะไหล่
                            <b-form-input
                              v-model="filter.wpCode"
                              type="search"
                              placeholder="รหัสค่าแรง/อะไหล่"
                              class="form-control"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </div>

                          <div class="col-12 col-sm-6 col-md-4 mb-4">
                            ชื่อค่าแรง/อะไหล่
                            <b-form-input
                              v-model="filter.nameTh"
                              type="search"
                              placeholder="ชื่อค่าแรง/อะไหล่"
                              class="form-control"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </div>

                          <div class="col-12 col-sm-12 col-md-12 text-end">
                            <b-button
                              class="btn btn float-end"
                              variant="info"
                              type="submit"
                              @click="handleSearch()"
                            >
                              <i class="uil-search"></i>
                              ค้นหา
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-skeleton-wrapper :loading="loading">
                      <template #loading>
                        <b-skeleton-table
                          :rows="5"
                          :columns="6"
                          :table-props="{ bordered: false, striped: true }"
                          animation="throb"
                        ></b-skeleton-table>
                      </template>
                      <div class="row mt-2">
                        <div class="col-sm-12 col-md-12">
                          <div class="row align-items-center">
                            <div class="col-sm-5 col-md-3">
                              <div
                                id="tickets-table_length"
                                class="dataTables_length"
                              >
                                <label
                                  class="d-inline-block align-items-center"
                                >
                                  แสดงผล
                                  <b-form-select
                                    v-model="perPage"
                                    size="sm"
                                    :options="pageOptions"
                                    @input="handlePageChange"
                                  ></b-form-select
                                  >&nbsp; รายการ
                                </label>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-6"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="table-responsive mb-0"
                        v-if="roType.type === 'W'"
                      >
                        <b-table
                          :items="rowsWage"
                          :fields="fieldsWage"
                          responsive="true"
                          :per-page="perPage"
                          :current-page="1"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          ref="selectableTable"
                          selectable
                          :select-mode="selectMode"
                          @row-selected="onRowSelected"
                        >
                          <template #cell(index)="rowsWage">
                            {{ rowsWage.index + 1 }}
                          </template>
                        </b-table>
                      </div>

                      <div
                        class="table-responsive mb-0"
                        v-if="roType.type === 'P'"
                      >
                        <b-table
                          :items="rowsPart"
                          :fields="fieldsPart"
                          responsive="true"
                          :per-page="perPage"
                          :current-page="1"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          ref="selectableTable"
                          selectable
                          :select-mode="selectMode"
                          @row-selected="onRowSelected"
                        >
                          <template #cell(index)="rowsPart">
                            {{ rowsPart.index + 1 }}
                          </template>
                        </b-table>
                      </div>

                      <div class="row">
                        <div class="col">
                          หน้า {{ currentPage }} จาก
                          {{ this.totalPage }} ทั้งหมด
                          {{ this.totalRecord }} รายการ
                        </div>

                        <div class="col">
                          <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRecord"
                                :per-page="perPage"
                                @change="handleChangePage"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                        <br />
                        <hr />
                        <br />
                      </div>
                    </b-skeleton-wrapper>
                  </div>
                </b-modal>
              </div>

              <!-- <div class="row">
                <div class="col-12">
                  <receiptroComponent
                    :taxInvId="this.taxInvId"
                    :totalPrice="this.totalPrice"
                    :dcPer="this.dcPer"
                    :dcPrice="this.dcPrice"
                    :netPrice="this.netPrice"
                    :vatPer="this.vatPer"
                    :vatPrice="this.vatPrice"
                    :grandTotalPrice="this.grandTotalPrice"
                  ></receiptroComponent>
                </div>
              </div> -->
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <b-modal
      ref="modalCreateTaxInvoice"
      id="modalCreateTaxInvoice"
      title="ค้นหารายการใบกำกับภาษี/ใบเสร็จรับ"
      hide-footer
      centered
      size="xl"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  สาขา:
                  <multiselect
                    v-model="filterCreate.branch"
                    label="nameTh"
                    type="search"
                    :options="optionBranch"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขที่ใบแจ้งซ่อม:
                  <b-form-input
                    v-model="filterCreate.roCode"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขใบแจ้งหนี้:
                  <b-form-input
                    v-model="filterCreate.licensePlate"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filterCreate.vin"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขที่ใบกำกับภาษี:
                  <b-form-input
                    v-model="filterCreate.licensePlate"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
            <b-button
              class="btn float-end"
              variant="info"
              type="submit"
              @click="handleSearchCreate"
            >
              <i class="uil-search-alt"></i>
              ค้นหา
            </b-button>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChangeCreate"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table
              :items="rowsCreate"
              :fields="fieldsCreate"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelectedRo"
            >
              <template #cell(index)="rowsCreate">
                {{ rowsCreate.index + 1 }}
              </template>
            </b-table>
          </div>

          <div class="row">
            <div class="col">
              หน้า {{ currentPageCreate }} จาก
              {{ this.rowsCreate.total }} ทั้งหมด {{ this.totalRecord }} รายการ
            </div>

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPageCreate"
                    :total-rows="totalRecord"
                    :per-page="perPageCreate"
                    @change="handleChangePageCreate"
                  ></b-pagination>
                </ul>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
        </b-skeleton-wrapper>
      </div>
    </b-modal>
  </Layout>
</template>
